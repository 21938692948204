
import { mapWritableState } from 'pinia'
import { useMarketStore } from '~/stores/market'

export default {
  name: 'MPPreOrderConfirmPopup',
  props: {
    // productName: {
    //   type: String,
    //   default: '',
    // },
  },
  setup() {
    const marketStore = useMarketStore()

    return { marketStore }
  },
  computed: {
    ...mapWritableState(useMarketStore, ['showPreOrderConfirmPopup']),
  },

  methods: {
    closeModal() {
      this.showPreOrderConfirmPopup = false
      this.marketStore.setPreOrderProduct('')
    },
  },
}
